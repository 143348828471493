<template>
  <div class="header">
    <i class="flaticon-lotus"></i>
    <div class="header-content container">
      <div class="row">
        <div class="col-lg-7">
          <div class="header-content">
            <div>
                          <h1><i class="flaticon-chakra"></i>Pranayama</h1>
            <h2>Welcome To Yoga Studio</h2>
            <p>
              Pranayama is a clean, flat and modern Yoga Studio &amp; Meditation
              website Template. It's the perfect Template for anyone who wants to
              create a professional website for Yoga, Pilates, Meditation, Spa,
              Wellness, Sports &amp; Health Websites.
            </p>
            <div class="my-btn">
              <router-link
                to="https://themeforest.net/user/hasnaadesign"
                target="_blank"
                class="main-btn"
                ><span>Buy Now</span>
              </router-link>
              <a
                href="#main-demos"
                class="main-btn custom-btn"
                v-smooth-scroll="{}"
                ><span>Go To Demos</span></a
              >
            </div>
            </div>

          </div>
        </div>
        <div class="col-lg-5 d-none d-lg-block">
          <div class="image">
            <img
              src="https://hasnaanajmi.com/env/Pranayama/images/home2.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "headerDemo",
};
</script>

<style scoped></style>