<template>
        <!-- ========== Start Footer ========== -->

    <div class="footer text-center">
        <div class="footer-bottom text-center">
            <div class="copyright">
                <p>© 2021 <span>Pranayama</span> All rights reserved</p>
            </div>
        </div>
    </div>

    <!-- ========== End Footer ========== -->
</template>

<script>


export default {
    name : "footerDemo",
}
</script>
