<template>
  <!-- ========== Start Inner Pages ========== -->

  <section class="inner-pages">
    <div class="container">
      <h2>+19 Other Pages</h2>
      <div class="row">
        <div class="col-lg-4">
          <div class="inner-box">
            <router-link
              to="/events"
              target="_blank"
            >
              <img
                class="img-fluid"
                src="../assets/innerpage/events.png"
                alt=""
              />
              <h5>Events</h5>
            </router-link>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="inner-box">
            <router-link
              to="/event-detailS"
              target="_blank"
            >
              <img
                class="img-fluid"
                src="../assets/innerpage/event-details.png"
                alt=""
              />
              <h5>Event Details</h5>
            </router-link>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="inner-box">
            <router-link
              to="/team"
              target="_blank"
            >
              <img
                class="img-fluid"
                src="../assets/innerpage/trainer.png"
                alt=""
              />
              <h5>Trainer</h5>
            </router-link>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="inner-box">
            <router-link
              to="/blog-3-column"
              target="_blank"
            >
              <img
                class="img-fluid"
                src="../assets/innerpage/blog-3-column.png"
                alt=""
              />
              <h5>Blog 3 Column</h5>
            </router-link>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="inner-box">
            <router-link
              to="/contact-us"
              target="_blank"
            >
              <img
                class="img-fluid"
                src="../assets/innerpage/contact-us.png"
                alt=""
              />
              <h5>Contact Us</h5>
            </router-link>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="inner-box">
            <router-link
              to="/about-us"
              target="_blank"
            >
              <img
                class="img-fluid"
                src="../assets/innerpage/about-us.png"
                alt=""
              />
              <h5>About Us</h5>
            </router-link>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="inner-box">
            <router-link
              to="/services"
              target="_blank"
            >
              <img
                class="img-fluid"
                src="../assets/innerpage/services.png"
                alt=""
              />
              <h5>Services</h5>
            </router-link>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="inner-box">
            <router-link
              to="/class-grid"
              target="_blank"
            >
              <img
                class="img-fluid"
                src="../assets/innerpage/class-grid.png"
                alt=""
              />
              <h5>Class Grid</h5>
            </router-link>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="inner-box">
            <router-link
              to="/class-details"
              target="_blank"
            >
              <img
                class="img-fluid"
                src="../assets/innerpage/class-details.png"
                alt=""
              />
              <h5>Class Details</h5>
            </router-link>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="inner-box">
            <router-link
              to="/pricing"
              target="_blank"
            >
              <img
                class="img-fluid"
                src="../assets/innerpage/pricing.png"
                alt=""
              />
              <h5>Pricing Plans</h5>
            </router-link>
          </div>
        </div>

      </div>
    </div>
  </section>

  <!-- ========== End Inner Pages ========== -->
</template>

<script>
export default {
  name: "innerPages",
};
</script>
