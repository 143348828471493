<template>
  <section id="main-demos" class="main-demos text-center">
    <div class="container">
      <div class="row">
        <!-- item -->
        <div class="col-lg-4 col-md-6">
          <div class="inner">
            <router-link to="/index-1" target="_blank">
              <img class="img-fluid" src="../assets/demos/1.png" alt="" />
              <h5>Home Demo - 1</h5>
            </router-link>
          </div>
        </div>
        <!-- item -->
        <div class="col-lg-4 col-md-6">
          <div class="inner">
            <router-link to="/index-2" target="_blank">
              <img class="img-fluid" src="../assets/demos/2.png" alt="" />
              <h5>Home Demo - 2</h5>
            </router-link>

          </div>
        </div>
        <!-- item -->
        <div class="col-lg-4 col-md-6">
          <div class="inner">
            <router-link to="/index-3" target="_blank">
              <img class="img-fluid" src="../assets/demos/3.png" alt="" />
              <h5>Home Demo - 3</h5>
            </router-link>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "mainDemo",
};
</script>
