<template>
       <!-- ========== Start Timetable Part ========== -->

    <section class="timetable">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="timetable-image">
                        <img class="img-fluid" src="../assets/timetable.jpg" alt="">
                        <img class="img-fluid two" src="../assets/class-details.jpg" alt="">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="timetable-info">
                        <h4>Awesome Timetable</h4>
                        <p>Pranayama comes with Beautiful Timetable Schedule allowing you to create daily and weekly Classes Timetable with an optional filter functionality.</p>
                        <router-link class="main-btn" to="/schedule" target="_blank"><span>View Now</span></router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- ========== End Timetable Part ========== -->
</template>

<script>


export default {
    name : "timeTable",
}
</script>
