<template>
        <!-- ========== Start Demo Features ========== -->

    <div class="demo-features text-center">
        <div class="container">
            <div class="row">
                 <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="item">
                        <img src="../assets/features/vue-cli.png" alt="">
                        <h3>Vue Cli</h3>
                    </div>
                </div>
                <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="item">
                        <img src="../assets/features/bootstrap.png" alt="">
                        <h3>Bootstrap 5x</h3>
                    </div>
                </div>
                <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="item">
                        <img src="../assets/features/html5.png" alt="">
                        <h3>HTML5</h3>
                    </div>
                </div>
                <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="item">
                        <img src="../assets/features/css3.png" alt="">
                        <h3>CSS3</h3>
                    </div>
                </div>
                <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="item">
                        <img src="../assets/features/scss.png" alt="">
                        <h3>SCSS</h3>
                    </div>
                </div>
                <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="item">
                        <img src="../assets/features/responsive.png" alt="">
                        <h3>Fully Responsive</h3>
                    </div>
                </div>
                <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="item">
                        <img src="../assets/features/owl-carousel.png" alt="">
                        <h3>Owl Carousel</h3>
                    </div>
                </div>
                <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="item">
                        <img src="../assets/features/clean-code.png" alt="">
                        <h3>Clean Code</h3>
                    </div>
                </div>
                <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="item">
                        <img src="../assets/features/google-font.png" alt="">
                        <h3>Google Fonts</h3>
                    </div>
                </div>
                <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="item">
                        <img src="../assets/features/w3c.png" alt="">
                        <h3>W3C Valid HTML5</h3>
                    </div>
                </div>
                <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="item">
                        <img src="../assets/features/well-documented.png" alt="">
                        <h3>Well Documented</h3>
                    </div>
                </div>
                <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="item">
                        <img src="../assets/features/font-awesome.png" alt="">
                        <h3>Font Awesome Icons</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- ========== End Demo Features ========== -->
</template>


<script>


export default{
    name : "demoFeatures"
}
</script>
